<div class="h-screen" *ngIf="notFound">
  <div *ngIf="notFound || !currentProject?.status" id="content">
    <app-not-found></app-not-found>
  </div>
</div>

<!-- <div class="h-screen" *ngIf="id && currentProject">
  <div *ngIf="!currentProject?.embedEnabled" id="content">
    <app-not-found></app-not-found>
  </div>
</div> -->

<div *ngIf="!projectId" class="flex justify-center w-full text-lg">
  Wir sind gleich wieder für Sie da.
</div>

<div *ngIf="isAlreadySubmitted">
  <div *ngIf="isAlreadySubmitted || !currentProject?.status" id="content">
    <div class="flex flex-col items-center justify-center">
      <h1 class="text-2xl font-bold">
        Sie haben den Funnel schon komplett ausgefüllt
      </h1>
    </div>
  </div>
</div>

<div *ngIf="">
  <div
    *ngIf="currentProject.progressbar.available"
    class=""
    style="
      position: fixed;
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      z-index: 3;
    "
    [ngStyle]="getProgress('background')"
  >
    <div
      style="transition: width 0.4s ease-out; position: absolute; height: 4px"
      [ngStyle]="getProgress('val')"
    ></div>
  </div>
</div>

<div
  *ngIf="currentProject && !notFound && !isAlreadySubmitted"
  [ngStyle]="getBackgroundColor()"
  class="h-screen overflow-auto"
>
  <div
    class="relative overflow-auto"
    [ngStyle]="getBackgroundColor()"
    [ngClass]="{
      'pb-10': id
    }"
  >
    <div
      *ngIf="showFormToast"
      class="absolute left-0 right-0 flex items-center w-full max-w-sm mx-auto overflow-hidden text-white bg-green-400 rounded-lg shadow-md fade-in"
    >
      <div class="w-10 px-2 border-r">
        <svg
          class="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
          ></path>
        </svg>
      </div>

      <div class="flex items-center px-2 py-3">
        <div class="mx-3">
          <p>Ihre Daten wurde erfolgreich übermitttelt!</p>
        </div>
      </div>
    </div>
    <div
      class="flex flex-col w-full"
      *ngFor="
        let items of html;
        let i = index;
        first as isFirst;
        last as isLast
      "
    >
      <div
        class="flex flex-col w-full"
        [ngStyle]="getBackgroundColor(items)"
        [ngClass]="{
          'justify-start items-start text-left': getAlignment(i) === 'start',
          'justify-center items-center text-center':
            getAlignment(i) === 'center',
          'justify-end items-end text-right': getAlignment(i) === 'end',
          'pb-36': isLast
        }"
      >
        <link [href]="getFontCSS(i)" rel="stylesheet" />

        <div
          class="flex justify-center w-full md:w-1/3 fade-in"
          *ngIf="items.type === 'button'"
        >
          <button
            class="w-full px-3 py-2 mx-2 my-2 text-sm text-gray-800 break-words whitespace-pre-line transition duration-150 ease-in-out bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none"
            [ngStyle]="getStyle(i)"
            (click)="buttonClick(items)"
            [ngClass]="{
              'text-center': getContentAlignment(i) === 'center',
              'text-left': getContentAlignment(i) === 'start',
              'text-right': getContentAlignment(i) === 'end'
            }"
          >
            {{ items.content }}
          </button>
        </div>

        <div
          class="flex justify-center w-full md:w-1/3 fade-in"
          *ngIf="items.type === 'textfield'"
        >
          <input
            [type]="items.content.type"
            [placeholder]="
              items.content.required
                ? items.content.text
                : items.content.text +
                  '
          (optional)'
            "
            [required]="items.content.required"
            [(ngModel)]="items.content.value"
            class="bg-gray-50 border-gray-300 text-gray-900 sm:text-sm rounded border focus:border-gray-500 focus:ring-0 p-2.5 mx-2 w-full"
            [ngStyle]="getStyle(i)"
            [ngClass]="{
              'border-red-300': errorIndex.includes(i),
              'text-center': getContentAlignment(i) === 'center',
              'text-left': getContentAlignment(i) === 'start',
              'text-right': getContentAlignment(i) === 'end'
            }"
            (focus)="focusIn(i)"
            (focusout)="focusOut(i)"
          />
        </div>

        <p
          *ngIf="items.type === 'header'"
          class="w-full max-w-md px-2 mx-auto break-words whitespace-pre-line sm:max-w-xl md:max-w-3xl lg:max-w-4xl fade-in"
          [ngStyle]="getStyle(i)"
          [attr.style]="getFont(i)"
          [innerHTML]="items.content"
        ></p>

        <!--  <app-header-block
          *ngIf="items.type === 'header'"
          (click)="getElement(items)"
          [ngStyle]="getStyle(i)"
          [block]="items"
        ></app-header-block> -->

        <div
          class="flex justify-center w-full md:w-1/3"
          *ngIf="items.type === 'video'"
        >
          <iframe
            *ngIf="!showCookieOverlay"
            [src]="getVideoUrl(items.content.url)"
            height="315"
            frameborder="0"
            allowfullscreen
            class="w-full"
            [ngStyle]="getStyle(i)"
          ></iframe>
          <img *ngIf="showCookieOverlay" src="./assets/placeholder.jpg" />
        </div>

        <div
          class="flex flex-col items-center justify-center w-full mx-2 fade-in md:w-1/3"
          *ngIf="items.type === 'bulletpoints'"
          [ngStyle]="getStyle(i)"
        >
          <div
            class="flex items-start justify-center w-full p-3 mx-2 mb-2 rounded"
          >
            <ul role="list" class="w-full">
              <li
                class="flex items-center w-full py-4"
                [ngClass]="{
                  'justify-center': getContentAlignment(i) === 'center',
                  'justify-start': getContentAlignment(i) === 'start',
                  'justify-end': getContentAlignment(i) === 'end'
                }"
                *ngFor="
                  let bp of items.content.bulletpoints;
                  let answerIndex = index
                "
              >
                <i [ngStyle]="getBulletpointIconStyle(i)" [ngClass]="bp.icon">
                </i>
                <div class="ml-3">
                  <p class="text-sm" [ngStyle]="getBulletpointStyle(i)">
                    {{ bp.text }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div
          [ngStyle]="getStyle(i)"
          *ngIf="items.type === 'quiz'"
          class="flex flex-wrap justify-center w-full fade-in"
        >
          <div
            class="mx-3 my-3"
            *ngFor="
              let answer of items.content.answers;
              let answerIndex = index
            "
          >
            <div
              class="flex flex-col items-center w-40 h-full transition transform border-gray-200 rounded-lg shadow-2xl cursor-pointer md:w-56 justify-evenly active:scale-90 hover:scale-105 hover:shadow-lg"
              [ngClass]="{
                'justify-between ': answer.type === 'picture',
                'justify-evenly': answer.type === 'icon',
                ' border-2 border-green-300':
                  items.content.isMultipleChoice &&
                  selectedAnswers.includes(answerIndex)
              }"
              [ngStyle]="getQuizButtonStyle(answerIndex, i)"
              (click)="selectAnswer(answer, answerIndex, items)"
            >
              <div
                class="relative flex flex-col items-center justify-between w-full"
              >
                <svg
                  *ngIf="
                    selectedAnswers.includes(answerIndex) &&
                    items.content.isMultipleChoice
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="absolute text-green-400 bg-white rounded-full right-4 top-2 bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                <img
                  *ngIf="answer.type === 'picture'"
                  [src]="getSantizeUrl(answer.answerPicture)"
                  class="object-cover w-full rounded-t-lg h-28 md:h-40"
                  [ngStyle]="getQuizPictureStyle(answerIndex, i)"
                />
                <div
                  *ngIf="answer.type === 'icon'"
                  class="flex items-center justify-center h-28 md:h-40"
                  [ngStyle]="getQuizIconStyle(answerIndex, i)"
                >
                  <i class="" [ngClass]="answer.answerIcon"> </i>
                </div>
              </div>
              <div
                class="flex items-start justify-center w-full h-full p-2 rounded-b-lg"
                [ngStyle]="getQuizTextStyle(answerIndex, i)"
              >
                <div
                  [innerHTML]="answer.answerText"
                  class="w-full px-2 text-center break-words bg-transparent border-transparent focus:border-transparent focus:ring-0"
                ></div>
              </div>
            </div>
          </div>
          <div
            *ngIf="items.content.isMultipleChoice"
            class="flex justify-center w-full px-5"
          >
            <button
              class="w-full py-2 my-1 text-sm text-gray-800 transition duration-150 ease-in-out bg-white border border-gray-300 rounded md:w-1/3 hover:bg-gray-100 focus:outline-none"
              [ngStyle]="getMultipleChoiceButtonStyle(items)"
              (click)="selectMultipleChoiceButton(items)"
            >
              <div
                contenteditable="false"
                [innerHTML]="
                  items.content.multipleChoiceButton
                    ? items.content.multipleChoiceButton.text
                    : 'Weiter'
                "
                class="w-full px-2 mx-auto bg-transparent border-transparent focus:border-transparent focus:ring-0"
                [id]="items.id"
              ></div>
            </button>
          </div>
        </div>

        <div
          class="flex flex-col items-center justify-center w-full mx-2 fade-in"
          *ngIf="items.type === 'selection'"
          [ngStyle]="getStyle(i)"
        >
          <div
            class="flex items-start justify-between w-full p-3 mb-4 rounded shadow-lg hover:bg-gray-100 md:w-1/3"
            [ngClass]="{
              'border-2': answer.checked,
              border: !answer.checked
            }"
            [ngStyle]="getSelectionStyle(i)"
            *ngFor="
              let answer of items.content.answers;
              let answerIndex = index
            "
          >
            <label
              [for]="'checkbox-' + answerIndex"
              class="w-full mr-3 text-sm font-medium text-left"
              [ngStyle]="getSelectionStyle(i)"
              [ngClass]="{
                'text-center': getContentAlignment(i) === 'center',
                'text-left': getContentAlignment(i) === 'start',
                'text-right': getContentAlignment(i) === 'end'
              }"
              >{{ answer.answerText }}
            </label>
            <input
              [id]="'checkbox-' + answerIndex"
              aria-describedby="checkbox-1"
              type="checkbox"
              [ngStyle]="getSelectionStyle(i)"
              class="w-5 h-5 border-gray-300 rounded-full bg-gray-50 focus:ring-0 focus:ring-offset-0"
              [(ngModel)]="answer.checked"
            />
          </div>
        </div>

        <div
          class="flex flex-col items-center justify-center w-full mx-2 fade-in"
          *ngIf="items.type === 'selectionquiz' && !items.showQuiz"
          [ngStyle]="getStyle(i)"
        >
          <div
            class="flex items-start justify-between w-full p-3 mb-4 rounded shadow-lg hover:bg-gray-100 md:w-1/3"
            [ngClass]="{
              'border-2': answer.checked,
              border: !answer.checked
            }"
            [ngStyle]="getSelectionStyle(i)"
            *ngFor="
              let answer of items.content.answers;
              let answerIndex = index
            "
          >
            <label
              [for]="'checkbox-' + answerIndex"
              class="w-full mr-3 text-sm font-medium text-left"
              [ngStyle]="getSelectionStyle(i)"
              [ngClass]="{
                'text-center': getContentAlignment(i) === 'center',
                'text-left': getContentAlignment(i) === 'start',
                'text-right': getContentAlignment(i) === 'end'
              }"
              >{{ answer.answerText }}
            </label>
            <input
              [id]="'checkbox-' + answerIndex"
              aria-describedby="checkbox-1"
              type="checkbox"
              [ngStyle]="getSelectionStyle(i)"
              class="w-5 h-5 border-gray-300 rounded-full bg-gray-50 focus:ring-0 focus:ring-offset-0"
              [(ngModel)]="answer.checked"
            />
          </div>
        </div>

        <div
          *ngIf="items.type === 'fakeloader'"
          class="flex justify-center w-full md:w-1/3 fade-in"
        >
          <div
            *ngIf="items.content.type === 'spinner'"
            class="border-2 border-solid rounded-full animate-spin border-t-transparent"
            [ngStyle]="getStyle(i)"
          ></div>
        </div>

        <img
          class="fade-in"
          *ngIf="items.type === 'picture'"
          [ngStyle]="getStyle(i)"
          [src]="getSantizeUrl(items.content)"
          [ngClass]="{
            'md:w-full w-0': items.style!.fullWidth!
          }"
        />

        <div
          class="flex justify-center w-full md:w-1/3 fade-in"
          *ngIf="items.type === 'socialmedia'"
          [ngClass]="{
            'text-left': getAlignment(i) === 'start',
            'text-center': getAlignment(i) === 'center',
            'text-right': getAlignment(i) === 'end'
          }"
        >
          <i
            class="cursor-pointer"
            (click)="openSocialMedia(items.content.url)"
            [ngClass]="getSocialMediaIcon(items.content.type)"
            [ngStyle]="getSocialMediaStyle(i, items.content.type)"
          ></i>
        </div>

        <!--   <div
          class="flex justify-center w-full mx-4 text-center md:w-1/3 fade-in"
          *ngIf="items.type === 'slider'"
        >
          <style>
            @media screen and (-webkit-min-device-pixel-ratio: 0) {
              input[type="range"]::-webkit-slider-thumb {
                width: 15px;
                -webkit-appearance: none;
                appearance: none;
                height: 15px;
                background: #fff;
                border-radius: 50%;
                box-shadow: -405px 0 0 400px #d3d3d3;
              }
            }
          </style>
          <div class="flex flex-col items-center justify-center w-full">
            <input
              class="h-4 overflow-hidden rounded-lg appearance-none"
              type="range"
              [min]="items.content.min"
              [max]="items.content.max"
              [value]="items.content.value"
              [(ngModel)]="items.content.value"
              [step]="items.content.steps"
              [ngStyle]="getStyle(i)"
            />
            <input
              type="text"
              [(ngModel)]="items.content.value"
              class="block w-24 p-1 mt-1 text-center text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md"
            />
          </div>
        </div> -->

        <div
          class="flex flex-col items-center justify-center w-full mx-2 fade-in md:w-1/3"
          *ngIf="items.type === 'upload'"
          [ngStyle]="getStyle(i)"
        >
          <ngx-dropzone
            (change)="uploadFile($event, i)"
            class="w-full h-full rounded hover:bg-gray-100"
            style="height: 250px; overflow: hidden"
          >
            <ngx-dropzone-label class="flex flex-col h-full hover:bg-gray-100">
              <div class="flex flex-auto w-auto h-full mx-auto max-h-48">
                <img
                  class="object-center w-auto mt-4 has-mask h-36"
                  [src]="items.content.picture"
                />
              </div>
              <p class="text-gray-500 pointer-none">
                <span class="text-sm">{{ items.content.text }}</span>
              </p>
            </ngx-dropzone-label>
          </ngx-dropzone>

          <div
            *ngIf="uploadLoader"
            class="flex flex-col items-center justify-center w-full mx-2 mt-2 rounded"
          >
            <div
              class="w-12 h-12 border-2 border-blue-500 border-solid rounded-full animate-spin border-t-transparent"
            ></div>
          </div>

          <div
            *ngIf="errorIndex.includes(i)"
            class="flex flex-col items-center justify-center w-full mx-2 mt-2 border border-red-400 rounded"
          >
            <p class="text-sm text-gray-800">
              {{
                currentProject.wording?.uploadBlockErrorMessage
                  ? currentProject.wording?.uploadBlockErrorMessage
                  : "Bitte laden Sie die Dateien hoch"
              }}
              (max. {{ items.content.maxUploads }} )
            </p>
          </div>
          <p *ngIf="showErrorTooBig" class="mt-2 text-sm text-gray-800">
            {{ currentProject.wording?.uploadBlockErrorTooBig }} (max. 3 MB)
          </p>
          <div
            class="flex items-center justify-between w-full px-2 mx-4 mt-2"
            *ngFor="
              let upload of items.content.uploads;
              let uploadIndex = index
            "
          >
            <div class="flex items-center space-x-2">
              <p class="text-sm">
                {{ uploadNames[uploadIndex].name }} {{ uploadIndex + 1 }}
              </p>
              <i class="text-green-500 bi bi-check-circle"></i>
            </div>
            <svg
              (click)="removeUpload(i, uploadIndex)"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="cursor-pointer bi bi-x"
              viewBox="0 0 16 16"
            >
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
              />
            </svg>
          </div>
        </div>

        <div
          [ngStyle]="getStyle(i)"
          *ngIf="items.type === 'selectionquiz' && items.showQuiz"
          class="flex flex-wrap justify-center w-full fade-in"
        >
          <div
            class="mx-3 my-3"
            *ngFor="
              let answer of items.content.answers;
              let answerIndex = index
            "
          >
            <div
              class="flex flex-col items-center w-40 h-full transition transform border-gray-200 rounded-lg shadow-2xl cursor-pointer md:w-56 justify-evenly active:scale-90 hover:scale-105 hover:shadow-lg"
              [ngClass]="{
                'justify-between ': answer.type === 'picture',
                'justify-evenly': answer.type === 'icon',
                ' border-2 border-green-300':
                  items.content.isMultipleChoice &&
                  selectedAnswers.includes(answerIndex)
              }"
              [ngStyle]="getQuizButtonStyle(answerIndex, i)"
              (click)="selectAnswer(answer, answerIndex, items)"
            >
              <div
                class="relative flex flex-col items-center justify-between w-full"
              >
                <svg
                  *ngIf="
                    selectedAnswers.includes(answerIndex) &&
                    items.content.isMultipleChoice
                  "
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="absolute text-green-400 bg-white rounded-full right-4 top-2 bi bi-check-circle-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
                  />
                </svg>
                <img
                  *ngIf="answer.type === 'picture'"
                  [src]="getSantizeUrl(answer.answerPicture)"
                  class="object-cover w-full rounded-t-lg h-28 md:h-40"
                  [ngStyle]="getQuizPictureStyle(answerIndex, i)"
                />
                <div
                  *ngIf="answer.type === 'icon'"
                  class="flex items-center justify-center h-28 md:h-40"
                  [ngStyle]="getQuizIconStyle(answerIndex, i)"
                >
                  <i class="" [ngClass]="answer.answerIcon"> </i>
                </div>
              </div>
              <div
                class="flex items-start justify-center w-full h-full p-2 rounded-b-lg"
                [ngStyle]="getQuizTextStyle(answerIndex, i)"
              >
                <link
                  [href]="getAnswerFontCSS(answerIndex, i)"
                  rel="stylesheet"
                />

                <div
                  [innerHTML]="answer.answerText"
                  class="w-full px-2 text-center break-words bg-transparent border-transparent focus:border-transparent focus:ring-0"
                ></div>
              </div>
            </div>
          </div>
          <div
            *ngIf="items.content.isMultipleChoice"
            class="flex justify-center w-full px-5"
          >
            <button
              class="w-full py-2 my-1 text-sm text-gray-800 transition duration-150 ease-in-out bg-white border border-gray-300 rounded md:w-1/3 hover:bg-gray-100 focus:outline-none"
              [ngStyle]="getMultipleChoiceButtonStyle(items)"
              (click)="selectMultipleChoiceButton(items)"
            >
              <div
                contenteditable="false"
                [innerHTML]="
                  items.content.multipleChoiceButton
                    ? items.content.multipleChoiceButton.text
                    : 'Weiter'
                "
                class="w-full px-2 mx-auto bg-transparent border-transparent focus:border-transparent focus:ring-0"
                [id]="items.id"
              ></div>
            </button>
          </div>
        </div>

        <div
          *ngIf="items.type === 'zw_westnetz'"
          class="flex flex-col flex-wrap items-center justify-center w-full px-4 fade-in"
          [ngStyle]="getStyle(i)"
        >
          <div class="w-full md:w-1/3">
            <div
              class="flex flex-col mt-2"
              *ngFor="let input of items.content.inputs; let inputIndex = index"
            >
              <div class="relative">
                <div
                  *ngIf="input.isIcon"
                  class="absolute flex items-center h-full pl-4 text-gray-600 cursor-pointer dark:text-gray-400"
                >
                  <i [ngClass]="input.icon"></i>
                </div>
                <input
                  class="flex items-center w-full h-10 text-sm font-normal bg-white border border-gray-300 rounded shadow focus:outline-none focus:border focus:border-gray-500 focus:ring-0"
                  [ngClass]="{ 'pl-12': input.isIcon, 'pl-4': !input.isIcon }"
                  [name]="input.text"
                  ngDefaultControl
                  [placeholder]="
                    input.required
                      ? input.text + '*'
                      : input.text + ' (optional)'
                  "
                  [type]="input.type"
                  [required]="input.required ? 'required' : false"
                  [(ngModel)]="input.value"
                  (focus)="focusFormInputIn(inputIndex)"
                  (focusout)="focusFormInputOut()"
                  [ngStyle]="getFormInputStyle(i, inputIndex)"
                />
              </div>
            </div>

            <p
              class="flex justify-end my-2 text-xs text-gray-600"
              *ngIf="!checkIfRequiredFieldsPresent()"
            >
              * Pflichtfelder
            </p>
          </div>
          <p
            *ngIf="westNetzErrorText"
            class="p-2 my-4 text-center text-black border border-red-400 rounded-lg"
          >
            {{ westNetzErrorText }}
          </p>
          <button
            [disabled]="westNetzLoader"
            (click)="submitWestnetzData(items, i)"
            [ngStyle]="getFormSubmitButtonStyle(i)"
            class="flex items-center justify-center w-full px-6 py-2 my-2 transition duration-150 ease-in-out border border-gray-300 rounded focus:outline-none md:w-1/3"
            (mouseover)="over()"
            (mouseout)="out()"
          >
            <p *ngIf="!westNetzLoader">
              {{ items.content.submitButton.text }}
            </p>
            <svg
              *ngIf="westNetzLoader"
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="w-10 h-10 bi bi-arrow-clockwise animate-spin"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              />
              <path
                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
              />
            </svg>
          </button>
        </div>

        <div
          *ngIf="items.type === 'form'"
          class="flex flex-col flex-wrap items-center justify-center w-full px-4 fade-in"
          [ngStyle]="getStyle(i)"
        >
          <div class="w-full md:w-1/3">
            <div
              class="flex flex-col mt-2"
              *ngFor="let input of items.content.inputs; let inputIndex = index"
            >
              <div class="relative">
                <div
                  *ngIf="input.isIcon"
                  class="absolute flex items-center h-full pl-4 text-gray-600 cursor-pointer dark:text-gray-400"
                >
                  <i class="text-lg" [ngClass]="input.icon"></i>
                </div>
                <input
                  class="flex items-center w-full h-16 text-sm font-normal bg-white border border-gray-300 rounded shadow focus:outline-none focus:border focus:border-gray-500 focus:ring-0"
                  (blur)="checkRequiredInputs(input, inputIndex)"
                  [ngClass]="{
                    ' border-red-300': errorIndex.includes(inputIndex),
                    'pl-12': input.isIcon,
                    'pl-4': !input.isIcon
                  }"
                  [name]="input.text"
                  ngDefaultControl
                  [placeholder]="
                    input.required
                      ? input.text + '*'
                      : input.text + ' (optional)'
                  "
                  [type]="input.type"
                  [required]="input.required ? 'required' : false"
                  [(ngModel)]="input.value"
                  (focus)="focusFormInputIn(inputIndex)"
                  (focusout)="focusFormInputOut()"
                  [ngStyle]="getFormInputStyle(i, inputIndex)"
                  [id]="'inputtext' + inputIndex"
                />
              </div>
            </div>

            <p
              class="flex justify-end my-2 text-xs text-gray-600"
              *ngIf="!checkIfRequiredFieldsPresent()"
            >
              *
              {{
                currentProject.wording?.formBlockRequired
                  ? currentProject.wording?.formBlockRequired
                  : "Pflichtfelder"
              }}
            </p>

            <div
              *ngIf="items.content.privacyCheck.required"
              class="flex-row w-full break-words"
            >
              <div
                class="p-2 mt-2 card"
                [ngClass]="{
                  ' border-red-300': privacyError
                }"
              >
                <div class="flex flex-row items-center justify-start">
                  <input
                    [(ngModel)]="items.content.privacyCheck.checked"
                    type="checkbox"
                    class="w-4 h-4 mr-2 border-gray-300 rounded focus:ring-transparent focus:outline-none"
                  />

                  <p
                    class="underline break-words whitespace-pre-line cursor-pointer"
                    [ngStyle]="getFormPrivacyCheckStyle(i)"
                    (click)="openPrivacyText(items.content.privacyCheck.link)"
                  >
                    {{ items.content.privacyCheck.text }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <button
            (click)="
              submitFormData(
                items.content.inputs,
                items.content.submitButton.action,
                i,
                items.content.privacyCheck
              )
            "
            [ngStyle]="getFormSubmitButtonStyle(i)"
            class="flex items-center justify-center w-full px-6 py-2 my-2 transition duration-150 ease-in-out border border-gray-300 rounded focus:outline-none md:w-1/3"
            (mouseover)="over()"
            (mouseout)="out()"
          >
            <p>{{ items.content.submitButton.text }}</p>
          </button>
        </div>

        <div
          *ngIf="isBackButtonAllowed(items) && currentPageIndex > 0"
          class="flex justify-center"
        >
          <button
            (click)="backToPreviousPage()"
            class="flex items-center w-auto p-3 text-sm text-gray-500 rounded focus:outline-none focus:shadow-outline"
          >
            <span class="mx-2">zurück</span>
          </button>
        </div>
        <!--     <div
          *ngIf="items.type === 'form'"
          class="flex flex-wrap justify-center w-full px-4"
          [ngStyle]="getStyle(i)"
        >
          <div class="w-full">
            <div
              class="flex flex-col mt-2"
              *ngFor="let input of items.content.inputs; let inputIndex = index"
            >
              <div class="relative">
                <div
                  *ngIf="input.isIcon"
                  class="absolute flex items-center h-full pl-4 text-gray-600 cursor-pointer dark:text-gray-400"
                >
                  <i [ngClass]="input.icon"></i>
                </div>
                <input
                  class="flex items-center w-full h-10 text-sm font-normal bg-white border border-gray-300 rounded-lg shadow focus:outline-none focus:border focus:border-gray-600"
                  [ngClass]="{ 'pl-12': input.isIcon, 'pl-4': !input.isIcon }"
                  [name]="input.text"
                  ngDefaultControl
                  [placeholder]="input.text"
                  [type]="input.type"
                  [required]="input.required ? 'required' : null"
                />
              </div>
            </div>

            <div
              *ngIf="items.content.privacyCheck.required"
              class="flex-row w-full break-words"
            >
              <div class="p-1 mt-2 card bordered">
                <div class="form-control">
                  <label class="flex justify-start cursor-pointer label">
                    <mat-checkbox class="mr-4 rounded-lg"> </mat-checkbox>
                    <p
                      class="break-words whitespace-pre-line"
                      [ngStyle]="getFormPrivacyCheckStyle(i)"
                    >
                      {{ items.content.privacyCheck.text }}
                    </p>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <button
            [ngStyle]="getFormSubmitButtonStyle(i)"
            class="w-full px-6 py-2 my-2 transition duration-150 ease-in-out border border-gray-300 rounded-lg focus:outline-none"
          >
            {{ items.content.submitButton.text }}
          </button>
        </div> -->
      </div>
    </div>

    <div
      class="z-auto flex justify-start w-full px-4 pt-20 group hover:border-blue-400"
      [ngStyle]="getBadgeBackgroundColor()"
      *ngIf="currentProject.showBadge"
    >
      <div
        class="flex flex-col items-center justify-center w-full h-full text-center transform rounded-b-lg group-hover:border-blue-400"
      >
        <a
          [href]="
            'https://klicklead.de?utm_source=funnel&utm_medium=badge&utm_campaign=' +
            currentProject.id
          "
          target="_blank"
          type="button"
          class="my-10 w-auto gap-x-2 inline-flex items-center px-3 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded-lg text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-kl-purple"
        >
          <p class="text-xs text-kl-grey">Erstellt mit</p>
          <img
            class="w-24"
            src="https://app.klicklead.de/assets/klicklead-logo.svg"
            alt="klicklead logo"
          />
        </a>
      </div>
    </div>

    <div
      *ngIf="currentProject.privacyEnabled"
      [ngStyle]="getBackgroundColor()"
      class="flex justify-center w-full pb-2 gap-x-10"
    >
      <a class="cursor-pointer" (click)="openImprint()">Impressum</a>
      <a class="cursor-pointer" (click)="openPrivacy()">Datenschutzerklärung</a>
    </div>
  </div>
</div>
<app-cookie-consent
  *ngIf="showCookieOverlay"
  (onAcceptCookies)="acceptCookies($event)"
  [projectId]="projectId"
  [project]="currentProject"
></app-cookie-consent>
<div id="klicklead-loaded"></div>
